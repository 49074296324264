<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-26 09:18:05
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-26 09:46:22
-->
<template>
    <div class="report-single-class">
        <el-row>
            <el-col :span="24" style="text-align: right;">
                <el-form label-width="80px" :inline='true'>
                    <el-form-item>
                        <el-date-picker v-model="date" type="date" placeholder="选择日期" size="mini"> </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入内容" v-model="selectAddress" class="input-with-select" size="mini" style="margin-top:8px;">
                            <el-select v-model="selectOptions" slot="prepend" placeholder="请选择" size="mini">
                                <el-option label="广东省信宜市" value="1"></el-option>
                            </el-select>
                            <el-button slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="query" size="mini">查询</el-button>
                        <el-button type="primary" @click="exprotExcel" size="mini">导出</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" style="width: 100%" ref="tableList" border>
            <el-table-column type="index" width="50" prop="序号" align="center" fixed></el-table-column>
            <el-table-column label="水电站名称" prop="name" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="所属县" prop="town" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="核准生态流量" prop="traffic" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="日均生态流量" prop="day" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="当日最小生态流量" prop="daysmall" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="未达标情况" prop="nodetail" align="center" :show-overflow-tooltip="true">
                <el-table-column label="未达标率" prop="no" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="未达标小时数" prop="nomonth" align="center" :show-overflow-tooltip="true"></el-table-column>
            </el-table-column>
            <el-table-column label="数据缺失情况" prop="datadetail" align="center" :show-overflow-tooltip="true">
                <el-table-column label="缺失率" prop="data" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="缺失小时数" prop="datamonth" align="center" :show-overflow-tooltip="true"></el-table-column>
            </el-table-column>
        </el-table>
        <div class="block nextpage">
            <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" 
                :current-page="currentPage" 
                :page-sizes="[8, 16, 32, 64]" 
                :page-size="pagesize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total="tableData.length">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [
                {name: '大成长坑水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '大成上坪河水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '大成斯茅坪水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '大成石景岭水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '大成亚雀垌水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '平塘兴源水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '屏丽水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '湖洋水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '丽沙水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
                {name: '白石白鸡水电站', town: '信宜市', traffic: '36', day: '36', daysmall: '36', no: '25%', nomonth: '3', data: '25%', datamonth: '3'},
            ],
            currentPage: 1, //初始页
            pagesize: 10, //每页的数据
        };
    },
    components: {
    },
    created() {
    },
    watch: {
        
    },
    methods: {
        /**
         * @description: 切换页数
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleSizeChange(val) {
            this.pagesize = val;
        },
        /**
         * @description: 切换页码
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        /**
         * @description: 按钮事件
         * @param {*} type
         * @return {*}
         * @author: Sunwx
         */        
        btnClick(type){
            switch(type){
                case "now":
                    break;
                case "prex":
                    break;
                case "next":
                    break;
            }
        },
        /**
         * @description: 查询
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        query(){
            alert('查询');
        },
        /**
         * @description: 导出
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        exprotExcel(){
            alert('导出');
        }
    }
}
</script>
<style>
.report-single-class{
    width: 100%;
}
.el-select .el-input {
    width: 130px;
}
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>

